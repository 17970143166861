@import "~@/styles/variables";



















































































.past-prescriptions-header {
  background: $prescription-tabs-bg;
}
