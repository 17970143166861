@import "~@/styles/variables";



























































































.autoship-status {
  &--off {
    color: $pp-dark-grey;
  }
}
