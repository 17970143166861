@import "~@/styles/variables";









































































































































.sig-description {
  font-size: inherit;
}
.input-padding {
  padding-top: $input-btn-padding-y;
  padding-bottom: $input-btn-padding-y;
}
