@import "~@/styles/variables";









































































.med-details__heading {
  font-weight: $f-weight-regular;
}
