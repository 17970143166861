@import "~@/styles/variables";






























































































































































.med-shipment-details__heading {
  margin-bottom: 0;
}

.med-shipment-details__details {
  display: inline-block;
  margin-top: 0.5rem;
}
