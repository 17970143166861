@import "~@/styles/variables";























































































::v-deep {
  .nav-tabs {
    background: $prescription-tabs-bg;
    margin-left: -$nav-tabs-border-width;
    margin-bottom: $nav-tabs-border-width;
  }

  .nav-link {
    border-color: $nav-tabs-link-hover-border-color;
    &.active {
      border-color: $nav-tabs-link-active-border-color;
    }

    @media (max-width: map-get($grid-breakpoints, 'sm')) {
      padding: 0.5em;
      font-size: 90%;
    }
  }
}
