@import "~@/styles/variables";




























































































































































.no-delivery {
  color: $pp-dark-grey;
}
