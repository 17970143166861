@import "~@/styles/variables";












































































































.medication-literature-answer {
  padding: 0 1.25rem;
  line-height: 1.5;
}
